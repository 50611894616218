import { Layout, Row, Col, Avatar, } from 'antd';

import React, { useState } from 'react';
import { Route, Switch } from "react-router-dom";
import { useStoreState } from './store/hooks/easyPeasy';
import TopNavBar from './topNavbar'
import { Sidebar } from './layouts/sidebar/Sidebar';
import Information from './container/information/InformationUniversity.page';
import Payment from './container/payment/PaymentUniversity.page';
import Report from './container/report/ReportUniversity.page';
import Icon, { MenuFoldOutlined, MenuUnfoldOutlined, PhoneOutlined } from '@ant-design/icons';

const { Header, Content, Sider } = Layout;

// Application
export default function ContainerUniversity() {
	const user = useStoreState(state => state.auth.user)
	const [collapsed, setCollapsed] = useState(false);
	const toggle = () => setCollapsed(!collapsed);
	var isMobile = false; //initiate as false
	if (
		/iP(hone|od)|android.+mobile|BlackBerry|IEMobile/i.test(navigator.userAgent)
	) {
		isMobile = true;
	}
	const onCollapse = collapsed => {
		//console.log("hello")
		//console.log(collapsed);
		setCollapsed(collapsed)
	};
	return (
		<Layout style={{ minHeight: '100vh' }}>
			<Sider
				collapsible
				collapsed={collapsed}
				onCollapse={onCollapse}
				breakpoint="lg"
				onBreakpoint={(broken) => {
					//console.log(broken);
				}}
				trigger={null}
				collapsedWidth={isMobile ? 0 : 80}
				width="220"
				style={{
					height: "100vh",
					overflow: "auto",
					position: isMobile ? "fixed" : "sticky",
					left: 0,
					top: 0,
					zIndex: 1000,
				}}
			>
				<div className="logo">
					{!collapsed &&
						<>
							<Avatar
								src={user?.instituteLogo}
								size={80}
								style={{ marginLeft: 55, marginTop: 15 }}
								className="pointer"
							/>
							<br />
						</>
					}
					{collapsed &&
						<Avatar
							src={user?.instituteLogo}
							size={"large"}
							style={{ marginLeft: 22, marginTop: 15 }}
							className="pointer"
						/>
					}
					{/* {!collapsed && <span style={{color:"white", fontSize:12}}> {user?.instituteName}</span>} */}
				</div>
				{user && <div onClick={() => setCollapsed(isMobile ? true : false)}> <Sidebar /></div>}
			</Sider>
			<Layout className="site-layout">
				<Header className="site-layout-background" style={{ padding: 0, }}>


					{React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
						className: 'trigger',
						onClick: toggle,
						style: { marginLeft: 20 }
					})}
					{!isMobile &&
						<>
							<span className="institute-name" style={{ marginLeft: 20, fontWeight: "bold", fontSize: 15 }}>{user?.instituteName} </span>
							<div style={{ float: "right", marginRight: 10 }}>
							<span style={{marginRight:50, fontWeight:"normal",color:"red" }}>Helpline: <a href="tel:+8809612191919" style={{color:"red"}}>09612-191919</a>, <a href="tel:+8801951901919" style={{color:"red"}}>01951-901919</a></span>
								<TopNavBar />
							</div>
						</>
					}
					{isMobile && collapsed &&
						<>
							<span className="institute-name" style={{ marginLeft: 20, fontWeight: "bold", fontSize: 15 }}>{user?.instituteName}</span>
							<div style={{ float: "right", marginRight: 10 }}>
								<TopNavBar />
							</div>
						</>
					}
					{isMobile && !collapsed &&
						<>

							{React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
								className: 'trigger',
								onClick: toggle,
								style: { marginTop: 0, float: "right", marginRight: 20 }
							})}
						</>
					}


				</Header>
				<Content style={{ margin: '0 16px' }}>
					<Switch>
						<Route exact path="/" component={Information} />
						<Route exact path="/payment" component={Payment} />
						<Route exact path="/report" component={Report} />
						{/* <Route exact path="/calendar" component={Calendar} /> */}
					</Switch>
				</Content>
				<Row>
					<Col  xs={24} sm={24} md={24} lg={24} xl={24}>
						<footer className="box-shadow">
							<div className="text-center">
								 Powered by Sheba Digital Limited & Sonali Bank Limited
							</div>
						</footer>
					</Col>
				</Row>
			</Layout>
			
		</Layout>
	);
}
