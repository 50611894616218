import React from 'react';
import { useStoreActions, Actions } from "easy-peasy";
import { StoreModel } from "./store/store";
import { Button, Card, Col, Popover, Row, Typography } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import { UserOutlined } from '@ant-design/icons';
import { useStoreState } from './store/hooks/easyPeasy';

const { Text } = Typography;

export default function TopNavBar() {
  const logout = useStoreActions(
    (actions: Actions<StoreModel>) => actions.auth.logout
  );
  const user = useStoreState(state => state.auth.user)
  const profilePopover = (onLogout, userData) => (
    <Card
      style={{ width: 300, marginTop: 16 }}
      actions={[
        <Button danger onClick={onLogout}>
          Logout
            </Button>,
      ]}
    >
      <div style={{ textAlign: "center" }}>
        {user?.imageName == null ?
          <Avatar size={100} icon={<UserOutlined />} /> :
          <Avatar size={100} src={'data:image/png;base64,' + user?.imageName} />
        }

      </div>
      <div style={{ textAlign: "center", marginTop: 12 }}>
        <Text strong>Name: {userData?.studentName} </Text>
      </div>
    </Card>
  );
  return (
    <>
      <Popover
        content={profilePopover(logout, user)}
        placement="bottomLeft"
        trigger="click"
      >
        {user?.imageName == null ?
          <Avatar
            icon={<UserOutlined />}
            size="large"
            style={{ marginRight: 5 }}
            className="pointer topUserImage"
          /> :
          <Avatar
            src={'data:image/png;base64,' + user?.imageName}
            size="large"
            style={{ marginRight: 5 }}
            className="pointer topUserImage"
          />
        }
      </Popover>

    </>
  );
}