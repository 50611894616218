import { createStore } from 'easy-peasy';
import { Auth, authStore } from './states/auth';

export interface StoreModel {
	auth: Auth;
}

const storeModel: StoreModel = {
	auth: authStore
}

export const store = createStore(storeModel);
