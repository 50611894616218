import { Alert, Form, Input, Button, Checkbox, Layout, Row, Col, Tooltip, Spin, Select } from 'antd';
import React, { useState } from 'react';
import { Redirect } from "react-router-dom";
import { InfoCircleOutlined, UserOutlined, LoadingOutlined, HomeOutlined, MailOutlined, PhoneOutlined, KeyOutlined } from '@ant-design/icons';
import logo from "./logo.png";
import topBanner from "./login-banner.png";
import { useStoreActions, useStoreState } from '../../store/hooks/easyPeasy';


// const FormItem = Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

// interface IProps {
//     logInConnect: (cred: any) => void;
//     authFailed: boolean;
//     authFailedMessage: string | undefined;
// }

// const layout = {
//     labelCol: { span: 8 },
//     wrapperCol: { span: 8 },
// };

// const tailLayout = {
//     wrapperCol: { offset: 8, span: 16 },
// };

// const { Option } = Select;



// function onFinish() {

// }

export function Login() {
    // const authFailed = false;
    // const authFailedMessage = '';
    const authenticate = useStoreActions(state => state.auth.authenticate);
    const authenticateUniversity = useStoreActions(state => state.auth.authenticateUniversity);
    const setBusy = useStoreActions(state => state.auth.setBusy);

    const error = useStoreState(state => state.auth.error);
    const isBusy = useStoreState(state => state.auth.busy);
    const user = useStoreState(state => state.auth.user);
    let rememberChoice: boolean = true;
    let type= '';
  
    if (user) {
        return <Redirect to="/" />
    }

    const onRememberChange = (event) => {
        rememberChoice = event.target.checked;
    }

    const onChangeInstituteType = (value) => {
      //  setType(value);
        type= value;
        localStorage.setItem("type", JSON.stringify(value));
    }
    const handleSubmit = async (payload) => {
        setBusy(true);
        payload.remember = rememberChoice;
        payload.type = rememberChoice;
        if (type==='school'){
            authenticate(payload);
        }        
        if (type==='university'){
            authenticateUniversity(payload);
        }
       
    };

    const style = {
        container: {
            // position: 'absolute' as 'absolute',
            background: '#360A64',
            // backgroundColor: '#6f42c1',
            // backgroundnoRepeat: 'no-repeat',
            // top: 0,
            // bottom: 0,
            // left: 0,
            // right: 0,
            // display: 'flex',
            // justifyContent: 'center',
            // alignItems: 'center'
        },
        asideLogoLeft: {
            backgroundImage: 'url(../../../mujib-borsho.png)',
        },
        asideLogoRight: {
            backgroundImage: 'url(../../../sonalibank.png)',
        },
        sonaliLogo: {
            backgroundImage: 'url(../../../sonalilogo.png)',
            marginRight: '10px'
        },
        shebaLogo: {
            backgroundImage: 'url(../../../logo-white.png)',
        }
    };
    var mobileDisplay = false; //initiate as false
    if (
        /iP(hone|od)|android.+mobile|BlackBerry|IEMobile/i.test(navigator.userAgent)
    ) {
        mobileDisplay = true;
    }
    return (
        <>
            <div className="login-page-wrapper" >
                <aside className="login-side-wrapper" style={style.container}>
                    <div className="login-details-wrapper">
                        <div className="login-ad-image">
                            <div className="sonali-logo" style={style.sonaliLogo}></div>
                            <div className="sheba-logo" style={style.shebaLogo}></div>
                        </div>
                        <div className="login-text">
                            <h3 className="login-title">Welcome to Student Sheba!</h3>
                            <h4 className="login-subtitle">Big Thinking Made Real.</h4>
                        </div>
                        {/* <div className="loginIcons">
                        <HomeOutlined />  <span>Dhaka, Bangladesh</span> <br />
                        <MailOutlined /><span> <a href="mailto:info@sheba-digital.com">info@sheba-digital.com</a> </span> 
                        <span>  <PhoneOutlined /> <a href="tel:09612-191919">09612-191919</a></span>
                    </div> */}
                    </div>
                </aside>
                <div className="login-form-wrapper">
                    <div className="loginTopBar">


                        <div className="mujib-logo" style={style.asideLogoLeft}></div>
                        <div className="text-between-image" >
                            সদয় অবগতির জন্য জানানো যাচ্ছে যে, ফি পেমেন্ট করার সময় পেমেন্ট কনফার্মেশন ম্যাসেজ পাচ্ছেন না এমন সমস্যা হলে অনুগ্রহ করে প্রথমে ব্যালান্স চেক করে নিশ্চিত হন টাকা কেটেছে কি না। যদি টাকা না কাটে তাহলে ১৫ মিনিট অপেক্ষা করুন এবং পুনুরায় ব্যালান্স চেক করে নিশ্চিত হন টাকা কেটেছে কি না। টাকা কেটে নেওয়ার পর অনুগ্রহ করে দ্বিতীয়বার পেমেন্ট করবেন না।

                            টাকা কেটেছে কিন্তু রিসিট ডাউনলোড করা যাচ্ছে না তাহলে উপরে দেওয়া নাম্বারে তাৎক্ষণিক যোগাযোগ করুন।
                        </div>



                        <div className="sonalibank-logo" style={style.asideLogoRight}></div>
                    </div>
                    <div className="login-form-inner" style={{ marginTop: 50 }}>
                        <div className="login-form-logo text-center">
                            <img src={logo} className="loginLogo" />
                        </div>
                        <div className="login-title text-center">
                            <p>Please login to your account</p>
                        </div>
                        <div style={{ background: '#fff', padding: '20px' }}>
                            <Form name="login" onFinish={handleSubmit}>
                                <Form.Item style={{ width: '330px' }} name='username' rules={[{ required: true, message: 'Please input your student id' }]}>
                                    <Input
                                        name="username"
                                        style={{ width: '330px' }}
                                        placeholder="Student Id"
                                        prefix={<UserOutlined className="site-form-item-icon" />}
                                        suffix={
                                            <Tooltip title="Student Id">
                                                <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                            </Tooltip>
                                        }
                                    />
                                </Form.Item>

                                <Form.Item style={{ width: '330px' }} name='password' rules={[{ required: true, message: 'Please input your EIIN No/Institute ID' }]}>
                                    <Input
                                        name="password"
                                        style={{ width: '330px' }}
                                        placeholder="EIIN No/Institute ID"
                                        prefix={<KeyOutlined className="site-form-item-icon" />}
                                        suffix={
                                            <Tooltip title="EIIN No/Institute ID">
                                                <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                            </Tooltip>
                                        }
                                    />
                                </Form.Item>
                                <Form.Item style={{ width: '330px' }} name='type' rules={[{ required: true, message: 'Please select institute type' }]}>
                                    <Select placeholder="Institute Type" style={{ width: "100%" }} onChange={onChangeInstituteType} >
                                        <Option value="school">School/Collge</Option>
                                        <Option value="university">University</Option>
                                    </Select>
                                </Form.Item>
                                {!mobileDisplay && <>
                                    <Row justify="space-between">
                                        <Col>
                                            <Checkbox defaultChecked onChange={onRememberChange}>Remember me</Checkbox>
                                        </Col>
                                        <Col>
                                            <Button type="primary" htmlType="submit" disabled={isBusy}>
                                                {isBusy ? <Spin indicator={antIcon} /> : 'Login'}
                                            </Button>

                                        </Col>
                                    </Row>
                                </>
                                }
                                {mobileDisplay &&
                                    <Row>
                                        <Col xs={24}>
                                            <Checkbox onChange={onRememberChange}>Remember me</Checkbox>
                                        </Col>

                                        <Col xs={24} className="mb-20" style={{ marginTop: '15px' }}>
                                            <Button type="primary" htmlType="submit" disabled={isBusy} block={true} style={{ height: '48px' }}>
                                                {isBusy ? <Spin indicator={antIcon} /> : 'Login'}
                                            </Button>

                                        </Col>
                                    </Row>
                                }

                                {
                                    error ? <Alert style={{ marginTop: '20px' }} message={error} type="error" /> : null
                                }
                            </Form>
                        </div>
                        <h4 className="helpline" style={{ color: "red", textAlign: "center" }}> <PhoneOutlined style={{ color: "red" }} /> Helpline: <a href="tel:+8809612191919" style={{ color: "red" }}>09612-191919</a>, <a href="tel:+8801951901919" style={{ color: "red" }}>01951-901919</a> </h4>
                    </div>
                    {/* <img className="loginLogo" src={logo} alt="login_logo" /> <br /> */}
                </div>
            </div>
            <footer className="box-shadow text-center">
                <span>Powered By <a href='https://www.sheba-digital.com/'>Sheba Digital Limited</a> & Sonali Bank Limited</span>
            </footer>
        </>
    );
}
