import { post, get, del } from "./httpuni";

export interface AuthRequest {
  username: string;
  password: string;
}
 
export const loginUni = (auth: AuthRequest) => {
  //console.group(auth)
  return get(
    `/public/student/login?customStudentId=${auth.username}&instituteId=${auth.password}`
  );
};

export const collectionListUni = (values: any) => {
  //console.group(auth)
  return get(
    `/public/online/fees/payable?instituteId=${values.instituteId}&semesterYearId=${values.semesterYearId}&studentId=${values.studentId}`
  );
};

export const submitDataFinalUni = (payload: any) => {
  return post("/public/get/online-fees-payment/spg/session-id", payload);
};
export const delInvoiceUni = (values: any) => {
  //console.group(auth)
  return del(
    `/public/fees-payment/delete/unnecessary/invoices?identificationId=${values.identificationId}`
  );
};

export const reportListUni = (values: any) => {
  //console.group(auth)
  return get(
    `/public/student/paid/invoices?instituteId=${values.instituteId}&semesterYearId=${values.semesterYearId}&studentId=${values.studentId}`
  );
};

