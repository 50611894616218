import { Action, Thunk, thunk, action } from 'easy-peasy';
import { login, collectionList, submitDataFinal, reportList } from '../../http/auth';
import { loginUni, collectionListUni, submitDataFinalUni, reportListUni } from '../../http/authuni';
import { message } from 'antd';

const API_BASE = process.env.REACT_APP_API_ROOT
const API_BASE_UNI = process.env.REACT_APP_API_ROOT_UNIVERSITY
export interface Auth {
	user: any | undefined,
	checkAuth: Thunk<Auth>;
	authenticated: Action<Auth, any>;
	authenticate: Thunk<Auth, any>;
	authenticateUniversity: Thunk<Auth, any>;
	logout: Action<Auth, any>;
	loginFailed: Action<Auth, string>;
	error?: string;
	busy?: boolean;
	setBusy: Action<Auth, boolean>;
	collectionList: Thunk<Auth, any>;
	collectionListUni: Thunk<Auth, any>;
	submitDataFinal: Thunk<Auth, any>;
	submitDataFinalUni: Thunk<Auth, any>;
	tableData: any,
	setTableData: Action<Auth, any>;
	loading: boolean,
	startLoading: Action<Auth, any>;
	stopLoading: Action<Auth, any>;
	reportData: any;
	setreportData: Action<Auth, any>;
	reportList: Thunk<Auth, any>;
	reportListUni: Thunk<Auth, any>;
	serviceCharge: any;
	setserviceCharge: Action<Auth, any>;
	checkType: any;
	setcheckType: Action<Auth, any>;
}

export let token: string | undefined = undefined;

export const authStore: Auth = {
	user: undefined,
	checkType: undefined,
	serviceCharge: 0,
	tableData: [],
	reportData: [],
	loading: false,
	checkAuth: thunk(async (actions) => {
		let jwt = localStorage.getItem("jwt");
		let type = localStorage.getItem("type");
		if (type==null||type==undefined){
			return;
		} else {
			actions.setcheckType(JSON.parse(type));
		}
		if (jwt) {
			try {
				console.log("Success")
				actions.authenticated(JSON.parse(jwt));
			} catch (e) {
				console.error("Error");
			}
		}
	}),
	authenticate: thunk(async (actions, payload) => {
		//console.log(payload)
		actions.setcheckType(payload.type);
		const response = await login(payload);

		//console.log(response.status)

		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			//sessionStorage.setItem("jwt", JSON.stringify(body?.item));
			console.log(body)
			if (body.messageType === 1) {
				if (payload.remember) {
					localStorage.setItem("jwt", JSON.stringify(body?.item));
				} else {
					localStorage.removeItem("jwt");
				}

				actions.authenticated(body?.item);

			} else {
				actions.loginFailed("Failed to login");
			}

		} else {
			actions.loginFailed("Failed to login");
		}
	}),
	authenticateUniversity: thunk(async (actions, payload) => {
		actions.setcheckType(payload.type);
		const response = await loginUni(payload);

		//console.log(response.status)

		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			//sessionStorage.setItem("jwt", JSON.stringify(body?.item));
			console.log(body)
			if (body.messageType === 1) {
				if (payload.remember) {
					localStorage.setItem("jwt", JSON.stringify(body?.item));
				} else {
					localStorage.removeItem("jwt");
				}

				actions.authenticated(body?.item);

			} else {
				actions.loginFailed("Failed to login");
			}

		} else {
			actions.loginFailed("Failed to login");
		}
	}),
	authenticated: action((state, auth) => {
		//console.log(auth)
		state.user = auth;
	}),	
	
	setcheckType: action((state, payload) => {
		//console.log(auth)
		state.checkType = payload;
	}),
	setBusy: action((state, isbusy) => {
		state.busy = isbusy;
	}),
	loginFailed: action((state, message) => {
		state.busy = false;
		state.error = message;
	}),
	logout: action((state) => {
		localStorage.removeItem("jwt");
		localStorage.removeItem("type");
		sessionStorage.removeItem("jwt");
		state.user = undefined;
		state.busy = false;
		state.error = "";
		window.location.reload()
	}),

	collectionList: thunk(async (actions, payload) => {
		//console.log(payload)
		actions.startLoading("start");
		const response = await collectionList(payload);

		//console.log(response.status)

		if (response.status === 201 || response.status === 200) {
			actions.stopLoading("stop");
			const body = await response.json();
			//	console.log(body)
			if (body?.item?.length === 0) {
				message.warning('No Data Found');
				actions.setTableData([])
				actions.setserviceCharge(0)
			} else if (body?.item === null) {
				message.warning(body?.message);
				actions.setTableData([])
				actions.setserviceCharge(0)
			} else {
				actions.setTableData(body?.item)
				actions.setserviceCharge(body?.serviceCharge)
			}

		} else {
			message.error('Something Went Wrong');
			actions.stopLoading("stop");
			//actions.loginFailed("Failed to login");
		}
	}),	
	collectionListUni: thunk(async (actions, payload) => {
		//console.log(payload)
		actions.startLoading("start");
		const response = await collectionListUni(payload);

		//console.log(response.status)

		if (response.status === 201 || response.status === 200) {
			actions.stopLoading("stop");
			const body = await response.json();
			//	console.log(body)
			if (body?.item?.length === 0) {
				message.warning('No Data Found');
				actions.setTableData([])
				actions.setserviceCharge(0)
			} else if (body?.item === null) {
				message.warning(body?.message);
				actions.setTableData([])
				actions.setserviceCharge(0)
			} else {
				actions.setTableData(body?.item)
				actions.setserviceCharge(body?.serviceCharge)
			}

		} else {
			message.error('Something Went Wrong');
			actions.stopLoading("stop");
			//actions.loginFailed("Failed to login");
		}
	}),
	submitDataFinal: thunk(async (actions, payload) => {
		//console.log(payload)
		actions.startLoading("start");
		const response = await submitDataFinal(payload);

		//console.log(response.status)

		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			actions.stopLoading("stop");
			if (body?.messageType === 1) {
				let requestUrl = `${API_BASE}/public/goto/spg-payment/portal?sessiontoken=${body?.item}`
				window.open(requestUrl, '_self');
			} else {
				message.error('Something Went Wrong');
			}


		} else {
			message.error('Something Went Wrong');
			//actions.loginFailed("Failed to login");
			actions.stopLoading("stop");
		}
	}),	
	submitDataFinalUni: thunk(async (actions, payload) => {
		//console.log(payload)
		actions.startLoading("start");
		const response = await submitDataFinalUni(payload);

		//console.log(response.status)

		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			actions.stopLoading("stop");
			if (body?.messageType === 1) {
				let requestUrl = `${API_BASE_UNI}/public/goto/spg-payment/portal?sessiontoken=${body?.item}`
				window.open(requestUrl, '_self');
			} else {
				message.error('Something Went Wrong');
			}


		} else {
			message.error('Something Went Wrong');
			//actions.loginFailed("Failed to login");
			actions.stopLoading("stop");
		}
	}),
	setTableData: action((state, payload) => {
		//console.log(auth)
		state.tableData = payload;
	}),	
	setserviceCharge: action((state, payload) => {
		//console.log(auth)
		state.serviceCharge = payload;
	}),
	startLoading: action((state) => {
		//console.log(auth)
		state.loading = true;
	}),
	stopLoading: action((state) => {
		//console.log(auth)
		state.loading = false;
	}),
	reportList: thunk(async (actions, payload) => {
		//console.log(payload)
		actions.startLoading("start");
		const response = await reportList(payload);

		//console.log(response.status)

		if (response.status === 201 || response.status === 200) {
			actions.stopLoading("stop");
			const body = await response.json();
			console.log(body)
			if (body?.item?.length === 0) {
				message.warning('No Data Found');
				actions.setreportData(body?.item)
			} else {
				let dt = body?.item.map(function (item, index) {
					return {
						key: index,
						customStudentId: item.customStudentId,
						feeHeads: item.feeHeads,
						feeInvoiceId: item.feeInvoiceId,
						feeSubHeads: item.feeSubHeads,
						masterId: item.masterId,
						paidAmount: item.paidAmount,
						paymentDate: item.paymentDate,
						paymentStatus: item.paymentStatus,
						studentName: item.studentName,
						studentRoll: item.studentRoll
					}
				})
				actions.setreportData(dt)
			}

		} else {
			message.error('Something Went Wrong');
			actions.stopLoading("stop");
			//actions.loginFailed("Failed to login");
		}
	}),	
	reportListUni: thunk(async (actions, payload) => {
		//console.log(payload)
		actions.startLoading("start");
		const response = await reportListUni(payload);

		//console.log(response.status)

		if (response.status === 201 || response.status === 200) {
			actions.stopLoading("stop");
			const body = await response.json();
			console.log(body)
			if (body?.item === null) {
				message.warning('No Data Found');
				actions.setreportData(body?.item)
			} else {
	
				actions.setreportData(body?.item)
			}

		} else {
			message.error('Something Went Wrong');
			actions.stopLoading("stop");
			//actions.loginFailed("Failed to login");
		}
	}),
	setreportData: action((state, payload) => {
		//console.log(auth)
		state.reportData = payload;
	}),
}
