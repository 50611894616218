import { post, get, del } from "./http";

export interface AuthRequest {
  username: string;
  password: string;
}

export const login = (auth: AuthRequest) => {
  //console.group(auth)
  return get(
    `/public/student-portal/login?customStudentId=${auth.username}&instituteId=${auth.password}`
  );
};

export const collectionList = (values: any) => {
  //console.group(auth)
  return post(
    '/public/fees-payment/view/for/online-collection', values
  );
};

export const submitDataFinal = (payload: any) => {
  return post("/public/get/online-fees-payment/spg/session-id", payload);
};

export const delInvoice = (values: any) => {
  //console.group(auth)
  return del(
    `/public/fees-payment/delete/unnecessary/invoices?identificationId=${values.identificationId}`
  );
};

export const reportList = (values: any) => {
  //console.group(auth)
  return get(
    `/public/fees-payment/invoice/list?identificationId=${values}`
  );
};

