import { Layout, Menu, Breadcrumb } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { DollarCircleOutlined, FilePdfOutlined, InfoCircleOutlined } from '@ant-design/icons/lib';
import SubMenu from 'antd/es/menu/SubMenu';

export function Sidebar(props:any) {
	// console.log(window.location.pathname)
	const [pathValue, setpathValue] = useState<any>("1")

	useEffect(() => {
		if (window.location.pathname == '/payment') {
			setpathValue("2")
		} else if (window.location.pathname == '/report') {
			setpathValue("3")
		}
		else {
			setpathValue("1")
		}
	}, [window.location.pathname])

	// console.log(pathValue)

	return <Menu theme="dark" defaultSelectedKeys={[pathValue]} mode="inline" selectedKeys={[pathValue]}>
		<Menu.Item key="1" icon={<InfoCircleOutlined />}>
			<Link to="/" className="nav-text">Information</Link>
		</Menu.Item>
		<Menu.Item key="2" icon={<DollarCircleOutlined />} >
			<Link to="/payment" className="nav-text">Payment</Link>
		</Menu.Item>
		<Menu.Item key="3" icon={<FilePdfOutlined />} >
			<Link to="/report" className="nav-text">Report</Link>
		</Menu.Item>
		{/* <SubMenu key="sub1" icon={ <UserOutlined/> } title="User">
			<Menu.Item key="3">Tom</Menu.Item>
			<Menu.Item key="4">Bill</Menu.Item>
			<Menu.Item key="5">Alex</Menu.Item>
		</SubMenu> */}


	</Menu>
}
