import { LoadingOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Form, Row, Select, Col, Button, Table, Input, Spin, Result, List, Card, Checkbox, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../store/hooks/easyPeasy';
import { delInvoice } from '../../http/auth';


export default function Payment(props) {
    const user = useStoreState(state => state.auth.user)
    const loading = useStoreState(state => state.auth.loading)
    const tableData = useStoreState(state => state.auth.tableData)
    const serviceCharge = useStoreState(state => state.auth.serviceCharge)
    const collectionList = useStoreActions(state => state.auth.collectionList);
    const submitDataFinal = useStoreActions(state => state.auth.submitDataFinal);

    const [form] = Form.useForm();

    // console.log(tableData)
    var isMobile = false; //initiate as false
    if (
        /iP(hone|od)|android.+mobile|BlackBerry|IEMobile/i.test(navigator.userAgent)
    ) {
        isMobile = true;
    }
    const [selectedRowKeys, setselectedRowKeys] = useState<any>([])
    const [pageTableData, setpageTableData] = useState<any>([])
    const [submitTableData, setsubmitTableData] = useState<any>([])
    const [totalPayable, settotalPayable] = useState<any>(0)

    useEffect(() => {
        //  console.log("hello")
        setselectedRowKeys([])
        settotalPayable(0)
        let tableDataa = tableData.map(function (item, index) {
            return {
                key: index,
                feeHeadId: item?.feeHeadId,
                feeHeadName: item?.feeHeadName,
                feeSubHeadId: item?.feeSubHeadId,
                feeSubHeadName: item?.feeSubHeadName,
                feeWaiverId: item?.feeWaiverId,
                feeWaiverName: item?.feeWaiverName,
                payableAmount: item?.payableAmount,
                waiverAmount: item?.waiverAmount,
            }
        })
        setpageTableData(tableDataa)
        let totalPayAbleVal = tableData
            .map(item => item.payableAmount)
            .reduce((prev, curr) => prev + curr, 0);
        settotalPayable(totalPayAbleVal)
        setsubmitTableData(tableData)
        let temp: any = []
        for (let i = 0; i < tableData.length; i++) {
            temp.push(i)
        }
        setselectedRowKeys(temp)
    }, [tableData])

    const onFinish = (values: any) => {
        delInvoice({ identificationId: user?.identificationId });
        // console.log(user?.identificationId);
        let payload: any = {
            identificationId: user?.identificationId,
            instituteId: user?.instituteId,
            year: values?.year,
            months: values?.month,
        }
        collectionList(payload);
    };

    const currentyear = new Date().getFullYear();

    // console.log(currentyear)

    const optionsYear = [
        { value: currentyear - 1, label: currentyear - 1 },
        { value: currentyear, label: currentyear },
        { value: currentyear + 1, label: currentyear + 1 }
    ]

    const optionsMonth = [
        { value: "January", label: "January" },
        { value: "February", label: "February" },
        { value: "March", label: "March" },
        { value: "April", label: "April" },
        { value: "May", label: "May" },
        { value: "June", label: "June" },
        { value: "July", label: "July" },
        { value: "August", label: "August" },
        { value: "September", label: "September" },
        { value: "October", label: "October" },
        { value: "November", label: "November" },
        { value: "December", label: "December" }

    ]

    const columns = [
        {
            title: 'Fee Head Name',
            dataIndex: 'feeHeadName',
            key: 'feeHeadName',
        },
        {
            title: 'Sub Head Name',
            dataIndex: 'feeSubHeadName',
            key: 'feeSubHeadName',
        },
        {
            title: 'Payable Amount',
            dataIndex: 'payableAmount',
            key: 'payableAmount',
        },
    ];
    const onSelectChange = (selectedRowKeys, value) => {
        // console.log('selectedRowKeys changed: ', selectedRowKeys);
        setsubmitTableData(value);
        setselectedRowKeys(selectedRowKeys);

        let totalPayAbleVal = value
            .map(item => item.payableAmount)
            .reduce((prev, curr) => prev + curr, 0);
        settotalPayable(totalPayAbleVal)

    };
    const rowSelection = {
        preserveSelectedRowKeys: true,
        selectedRowKeys,
        onChange: onSelectChange,
    };

    console.log(selectedRowKeys)

    const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

    const onPay = () => {
        let details = submitTableData.map(function (item) {
            return {
                "feeAmount": item?.payableAmount,
                "feeHeadId": item?.feeHeadId,
                "feeSubHeadId": item?.feeSubHeadId,
                "waiverAmount": item?.waiverAmount,
                "waiverId": item?.feeWaiverId
            }
        });
        // let details = tableData.map(function (item) {
        //     return {
        //         "feeAmount": item?.payableAmount,
        //         "feeHeadId": item?.feeHeadId,
        //         "feeSubHeadId": item?.feeSubHeadId,
        //         "waiverAmount": item?.waiverAmount,
        //         "waiverId": item?.feeWaiverId
        //     }
        // });
        let postData: any = {
            identificationId: user?.identificationId,
            serviceCharge: serviceCharge,
            details: details
        };
        submitDataFinal(postData)
    }

    const mobileSelect = (e, item) => {
        let temp;
        if (e) {
            temp = [...submitTableData, item]
            setsubmitTableData(temp)
            let totalPayAbleVal = temp
                .map(item => item.payableAmount)
                .reduce((prev, curr) => prev + curr, 0);
            settotalPayable(totalPayAbleVal)

        }
        if (!e) {
            temp = submitTableData.filter(i => i.feeSubHeadId !== item.feeSubHeadId);
            setsubmitTableData(temp)
            let totalPayAbleVal = temp
                .map(item => item.payableAmount)
                .reduce((prev, curr) => prev + curr, 0);
            settotalPayable(totalPayAbleVal)

        }

    }

    return (
        <div className="mt-25 mb-sm-25">
            <Card title="Make Payment" extra={
                <Tooltip title="সদয় অবগতির জন্য জানানো যাচ্ছে যে, ফি পেমেন্ট করার সময় পেমেন্ট কনফার্মেশন ম্যাসেজ পাচ্ছেন না এমন সমস্যা হলে অনুগ্রহ করে প্রথমে ব্যালান্স চেক 
                করে নিশ্চিত হন টাকা কেটেছে কি না। যদি টাকা না কাটে তাহলে ১৫ মিনিট অপেক্ষা করুন এবং পুনুরায় ব্যালান্স চেক করে নিশ্চিত হন টাকা কেটেছে কি না। 
                টাকা কেটে নেওয়ার পর অনুগ্রহ করে দ্বিতীয়বার পেমেন্ট করবেন না। টাকা কেটেছে কিন্তু রিসিট ডাউনলোড করা যাচ্ছে না তাহলে 09612-191919 অথবা 01951-901919 নাম্বারে তাৎক্ষণিক যোগাযোগ করুন।">
            <QuestionCircleOutlined />
            </Tooltip>
            }>
                <div className="loading" style={{ display: loading ? "inherit" : "none" }}> <Spin indicator={antIcon} /></div>
                <Form layout="vertical" onFinish={onFinish} id='create-class' form={form} className="mb-sm-25" >
                    <Row gutter={15}>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>

                            <Form.Item
                                name="year"
                                label="Year:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please Select Year" },
                                ]}
                            >
                                <Select allowClear placeholder="Select Year" options={optionsYear} />


                            </Form.Item>

                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>

                            <Form.Item
                                name="month"
                                label="Month:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please Select Month" },
                                ]}
                            >
                                <Select mode="multiple" allowClear placeholder="Select Month" options={optionsMonth} />


                            </Form.Item>

                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item className="mb-sm-0">
                                <Button type="primary" htmlType="submit" style={{ marginTop: isMobile ? 0 : 30, width: "100%", padding: 10, height: "auto" }}>
                                    Search
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
            {/* <br /> */}
            {tableData.length > 0 && <span style={{ fontWeight: "bold", fontSize: 18, marginTop: 15, marginBottom: 10, display: "block" }}>List of fees:</span>}
            {!isMobile && <><br /></>}
            {/* <Skeleton active /> */}
            {tableData.length > 0 &&
                <>

                    {
                        isMobile ?
                            <List
                                itemLayout="vertical"
                                dataSource={tableData}
                                size="large"
                                pagination={false}


                                renderItem={(item: any) => (
                                    <List.Item key={item.key}>
                                        <ul className="w3-ul w3-card-4 payment-fee-list">
                                            <li className="w3-bar">
                                                <span className="w3-bar-item w3-button w3-white w3-xlarge w3-right">{item?.payableAmount}</span>
                                                <div className="w3-bar-item">
                                                    <div style={{ display: "flex", justifyItems: "center", alignContent: "center", alignItems: 'center' }}>
                                                        <div>
                                                            <Checkbox defaultChecked onChange={(e) => mobileSelect(e.target.checked, item)} ></Checkbox>
                                                        </div>
                                                        <div style={{ marginLeft: 10 }}>
                                                            <span className="w3-large">{item?.feeHeadName}</span><br />
                                                            <span>{item?.feeSubHeadName}</span>
                                                        </div>

                                                    </div>

                                                </div>
                                            </li>
                                        </ul>

                                    </List.Item>
                                )}
                            /> :
                            <Table
                                bordered={true}
                                dataSource={pageTableData}
                                columns={columns}
                                rowSelection={rowSelection}
                            />
                    }
                    {isMobile &&
                        <>
                            <span style={{ marginTop: 10, fontSize: 10, marginBottom: -10 }}> * {serviceCharge} Taka Service Charge is also payable with Total Payable</span>
                            <div className="payableWrapper">
                                <div className="totalPayable">
                                    <div className="totalPayableTitle"> Total Payable</div>
                                    <h3 className="totalPayableCount">{totalPayable}</h3>
                                </div>
                                <div className="bg">
                                </div>
                            </div>

                            <Button type="primary" className="mb-sm-25" style={{ width: isMobile ? "100%" : "100%", marginTop: isMobile ? 20 : 20, float: "right", padding: 10, height: "auto" }} disabled={totalPayable > 0 ? false : true} onClick={() => onPay()}>
                                Pay Now
                            </Button>
                        </>

                    }
                    {!isMobile &&
                        <Row gutter={[20, 20]}>
                            <Col xs={24} sm={24} md={24} lg={18} xl={18}>
                                * {serviceCharge} Taka Service Charge is also payable with Total Payable
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={3} xl={3}>
                                <Form.Item>
                                    <span style={{ fontWeight: "bold" }}>Total Payable:</span>
                                    <Input placeholder="Total Payable" disabled value={totalPayable} />
                                </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={24} lg={3} xl={3}>
                                <Form.Item>
                                    <div className="mb-sm-25">
                                        <Button type="primary" id="mb-sm-25demo" style={{ width: isMobile ? "100%" : "100%", marginTop: isMobile ? 20 : 20, float: "right" }} disabled={totalPayable > 0 ? false : true} onClick={() => onPay()}>
                                            Pay
                                        </Button>
                                    </div>
                                </Form.Item>
                            </Col>

                        </Row>
                    }
                    <br />
                </>
            }

        </div>
    )
}
